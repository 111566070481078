import consumer from "./consumer"

export function subscribeToResultsChannel(id, onSuccess, onFailure) {
  const subscription = consumer.subscriptions.create({ channel: "SpamTestResultsChannel", id: id }, {
    connected() {
      // TODO: start timer to see disconnect if message does not come in some time
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      consumer.subscriptions.remove(subscription);
    },

    received(data) {
      consumer.subscriptions.remove(subscription);
      onSuccess(data)
    }
  });
}

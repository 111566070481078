import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get togglers() {
    return this.context.bindingObserver.bindings
               .filter(binding => binding.action.methodName == 'toggle')
               .map(binding => binding.action.element);
  }

  get contents() {
    return this.scope.findAllElements('[data-accordion-id]')
  }

  connect() {
  }

  disconnect() {
  }

  toggle(e) {
    this.togglers.forEach(toggler => {
      if (toggler.contains(e.target)) {
        console.log(toggler)
        this.toggleClass(toggler)
      }
    });

    e.preventDefault();
  }

  toggleClass(toggler) {
    const content = this.findContent(toggler);
    console.log(content)
    if (!this.isOpened(toggler)) {
      toggler.classList.add('st-accordion__icon--opened');
      content.classList.add('st-accordion__content--visible');
    } else {
      toggler.classList.remove('st-accordion__icon--opened');
      content.classList.remove('st-accordion__content--visible');
    }
  }

  isOpened(toggler) {
    return toggler.matches('.st-accordion__icon--opened');
  }

  findContent(toggler) {
    let id = this.getID(toggler);
    return this.scope.findElement(`[data-accordion-id="${id}"]`);
  }

  getID(toggler) {
    return toggler.getAttribute('href').replace(/^#/, '');
  }
}

import { Controller } from "@hotwired/stimulus"
import { subscribeToResultsChannel } from "../channels/spam_test_results_channel"

export default class extends Controller {
  static values = { id: String }

  connect() {
    const id = this.idValue;
    if (id) {
      // Subscribe to the Action Cable channel for the given result ID
      subscribeToResultsChannel(id, function(data) {
        const results = document.getElementById('results');
        results.innerHTML = data
        results.parentElement.querySelector("#loader").remove();
      });
    }
  }

  disconnect() {
  }
}
